import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import {SLIDES_TYPES} from '@/const';

export const initialState = {
  settings: {},
  menu: [],
  groupMenu: [],
  participantMenu: [],
  slides: {
    [SLIDES_TYPES.main]: [],
    [SLIDES_TYPES.category]: {},
    [SLIDES_TYPES.participant]: {},
    [SLIDES_TYPES.group]: {},
    requested: false,
  },
  pages: [],
};

export default {
  state: JSON.parse(JSON.stringify(initialState)),
  mutations,
  actions,
  getters,
};
